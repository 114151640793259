:root {
    --primary: #3A9DDD;
  }
  
  .btn {
    padding: 8px 20px;
    border-radius: 4px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease-out;
  }
  
  
  .btn-primary {
    background-color: var(--primary);
  }
  
  .btn-outline {
    background-color: transparent;
    color: #fff;
    border: 1px solid var(--primary);
  }
  
  .btn-medium {
    font-size: 18px;
    color: #fff;
  }
  
  .btn-large {
    padding: 12px 20px;
    font-size: 20px;
    color: #fff;
  }
  