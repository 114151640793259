/* App.css */

body {

padding: 0;
margin: 0;
font-family: monospace, serif;


}

.hero-banner {
  background-color: #092434;
  padding: 80px 0;
  text-align: center;
}

.hero-title {
  font-size: 3rem;
  margin-bottom: 20px;
  color: white;
}

.hero-subtitle {
  font-size: 1.5rem;
  color: white;
}

.about-section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 80px 0;
  text-align: center;
  background-color: #092434;

}

.about-content {
  flex: 1;
  padding-right: 20px;
}

.about-image {
  flex: 1;
  text-align: center;
}

.about-image img {
  max-width: 100%;
  height: auto;
}

.about-title {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: white;
}

.about-text {
  font-size: 1rem;
  margin-left: 2rem;
  color: white;

}

.skills-section {
  background-color: #092434;
  padding: 80px 0;
  text-align: center;
}

.skills-title {
  font-size: 2.5rem;
  margin-bottom: 40px;
  color: white;
}

.skills-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.skill-item {
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  padding: 10px 20px;
  margin: 10px;
  font-size: 1.2rem;
}

@media (max-width: 768px) {
  .hero-title {
    font-size: 2.5rem;
  }

  .hero-subtitle {
    font-size: 1.2rem;
  }

  .about-section {
    flex-direction: column;
  }

  .about-content,
  .about-image {
    padding: 0;
    text-align: center;
    width: 100%;
  }

  .skills-list {
    padding: 0 20px;
  }

  .skill-item {
    width: calc(50% - 20px);
  }
}

@media (max-width: 480px) {
  .hero-title {
    font-size: 2rem;
  }

  .hero-subtitle {
    font-size: 1rem;
  }

  .skill-item {
    width: 100%;
  }
}