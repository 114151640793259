body {

padding: 0;
margin: 0;
background-color: #092434;

}

/* Basic styling for the portfolio container */
.portfolio-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
}

/* Styling for the portfolio title */
.portfolio-title {
    text-align: center;
    font-size: 2.5em;
    margin-bottom: 40px;
    color: white;
    animation: typing 3s steps(22) forwards, blink-caret 0.75s step-end infinite;

}

/* Styling for the projects container */
.projects {
    display: flex;
    justify-content: center; /* Center the items */
    gap: 20px; /* Add space between the items */
    flex-wrap: wrap;
}

/* Styling for each project */
.project {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 30%; /* Each project takes up 30% of the container */
    max-width: 300px; /* Maximum width for each project */
    text-align: center;
    transition: transform 0.3s ease-in-out;
    margin: 20px 0; /* Add vertical margin for spacing */
}

.project:hover {
    transform: translateY(-10px);
}

/* Image styling within each project */
.project img {
    width: 90%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

/* Project title styling */
.project h3 {
    margin: 20px 0 10px;
    font-size: 1.5em;
    color: #333;
}

/* Project description styling */
.project p {
    padding: 0 15px 20px;
    color: #666;
}

/* View project button styling */
.view-project-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    margin-bottom: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
}

.view-project-button:hover {
    background-color: #0056b3;
}

/* Responsive design */
@media (max-width: 768px) {
    .project {
        width: 45%;
    }
}

@media (max-width: 480px) {
    .project {
        width: 100%;
    }
}
